@use "sass:map";

.memorial-directory {
  &.container {
    padding-bottom: 0;
  }

  .body-card {
    margin-top: 40px;
    padding: 0;
  }
}

.memorial-card {
  @include el-light-border-bottom;

  flex-direction: column;
  justify-content: space-between;
  padding: 20px;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    padding: 40px;
  }

  .link-large {
    margin-bottom: 7px;
  }

  p {
    margin-bottom: 0;

    @include media-breakpoint-between(xs, md) {
      font-size: 1rem;
    }
  }

  &:last-of-type {
    @include media-breakpoint-up(md) {
      border-bottom: 0;
    }
  }

  .memorial-card-info {
    @include media-breakpoint-up(lg) {
      margin-right: 40px;
    }
  }

  // TODO: May be able to combine this photo-container with that used in dashboard.
  .memorial-card-photo-container {
    flex-shrink: 0;
    height: 75px;
    max-width: 240px;
    width: 102px;

    @include media-breakpoint-up(md) {
      height: 180px;
      width: $profile-photo-medium;
    }

    .profile-photo {
      border: solid 0.75px $black-with-33-percent-opacity;
      border-radius: 3px;
      height: 100%;
      width: 100px;

      @include media-breakpoint-up(md) {
        border: 0;
        border-radius: $border-radius-lg;
        width: 240px;
      }
    }
  }

  .deceased-info {
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    @include media-breakpoint-up(md) {
      margin-left: 40px;
    }

    p {
      &:first-of-type {
        margin-top: 10px;
      }
    }
  }

  .obituary-summary {
    margin-top: 10px;

    p {
      display: inline;

      &:last-of-type {
        margin-right: map.get($spacers, 1);
      }
    }
  }

  .memorial-btn {
    padding-right: 0;

    .btn {
      margin-right: 0;
      min-width: 168px;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
}
