// Memorial events

.memorial-events-container {
  .memorial-event {
    @include el-light-border-bottom;

    margin-bottom: 40px;
    padding-bottom: 40px;

    &:last-child {
      border: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.profile-memorial-events-container {
  .memorial-event {
    @include el-light-border-bottom;

    margin-bottom: 40px;
    padding-bottom: 40px;

    &:last-child {
      border: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .link-large {
    margin-bottom: 10px;
  }
}

.event-map {
  height: 280px;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }

  iframe {
    height: 100%;
    width: 100%;

    @include media-breakpoint-up(md) {
      border-radius: $border-radius-lg;
    }
  }
}

.event-video {
  .event-video-title {
    margin: 14px 0;

    @include media-breakpoint-up(md) {
      margin: 24px 0 14px;
    }
  }

  .event-video-container {
    height: 0;
    overflow: hidden;
    padding-top: calc(9 / 16 * 100%);
    position: relative;
    width: 100%;

    @include media-breakpoint-up(md) {
      border-radius: $border-radius-lg;
    }

    iframe {
      border: 0;
      height: 100%;
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.event-type-header {
  a {
    &.event-type {
      margin-bottom: 10px;
    }
  }
}

.attending {
  margin: 8px 0;
}

.rsvp-attendees-collapsed {
  display: none;
}

.rsvp-details-collapsed {
  display: none;
}

.rsvp-collapsed {
  display: none;
}

.rsvp-expanded {
  color: $lighter-gray-text !important;
}

.rsvp-response-btns {
  margin-bottom: 10px;

  .btn {
    margin: 0 0 10px;
    order: 1;
    width: 100%;

    @include media-breakpoint-up(md) {
      margin: 0;
      width: auto;
    }
  }

  .btn-cancel-rsvp {
    order: 2;

    @include media-breakpoint-up(md) {
      margin-left: 20px;
    }
  }
}

.event-rsvp-details-container {
  .toggle-event-rsvp-details {
    .caret {
      margin-bottom: 1px;
      margin-left: 5px;
      transition: transform 0.35s ease;
    }

    &[aria-expanded="true"] {
      .caret {
        transform: rotate(180deg);
      }
    }
  }

  .event-rsvp-details {
    margin-top: 10px;

    @include media-breakpoint-up(md) {
      margin-left: 34px;
    }
  }
}

// Obituary page

.obituary-container {
  font-family: "Gelasio", serif;

  p {
    line-height: 2.125rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Flowers page

.memorial-flowers-container {
  width: 100%;

  .header-breadcrumbs {
    ol {
      margin-bottom: 20px;
    }
  }

  .subtitle {
    margin-bottom: 40px;
  }

  .flowers-container {
    .product {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: calc(50% - 20px);
      }

      @include media-breakpoint-up(lg) {
        width: calc((100% - 80px) / 4);
      }
    }
  }

  .flowers-products-cta {
    text-transform: uppercase;
  }
}

.memorial-checkout-container {
  padding: 0;

  @include media-breakpoint-up(md) {
    padding: 40px 50px;
  }

  @include media-breakpoint-up(lg) {
    padding: 40px 0;
  }

  .memorial-flowers-checkout-header {
    padding: 20px;
  }

  .checkout-container {
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
  }
}

.prompt-forms-container {
  border-bottom: 0 !important;
  padding: 0;

  @include media-breakpoint-up(md) {
    padding: 40px 50px;

    .carousel {
      border-radius: $border-radius-lg;
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 40px 0;
  }

  .prompt {
    padding: 20px;

    .prompt-header {
      margin-bottom: 10px;

      .prompt-text {
        font-weight: $font-weight-semibold;
        word-break: break-word;
        word-wrap: break-word;
      }

      .next-prompt {
        margin-left: 10px;

        img {
          height: 24px;
          width: 24px;
        }
      }
    }

    .answer-text,
    .memory-text {
      height: 100px;
      width: 100%;
    }

    button {
      margin: 10px 0 0;
    }
  }
}

.prompt-forms-separator {
  @include el-light-border-bottom;
}

.covid-precautions-header {
  margin: 14px 0;

  @include media-breakpoint-up(md) {
    margin: 24px 0 14px;
  }
}

.print-obituary-download-container {
  .print-obituary-thumbnail-container {
    height: 50vw;
    width: 80vw;

    @include media-breakpoint-up(md) {
      height: 220px;
      width: 340px;
    }
  }

  .print-obituary-thumbnail {
    background-repeat: no-repeat;
    background-size: cover;
    mask-image: linear-gradient(rgb(0 0 0 / 1), transparent);
  }
}
