.mobile-donate-drawer-cta-container {
  @include el-border-top;

  background-color: $white;
  bottom: 0;
  box-shadow: -1px -1px 3px 0 $purple-with-10-percent-opacity;
  padding: 10px 20px 20px;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: $zindex-modal;

  h2 {
    margin-bottom: 10px;
  }

  img {
    margin-bottom: 5px;
  }

  .link-style-text {
    font-size: 1.3125rem;
    margin-bottom: 20px;
  }

  .sharing-cta-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
  }

  p {
    font-size: 1rem;
    font-weight: $font-weight-light;
  }

  .cta {
    .cta-btn-group {
      margin-top: 20px;
    }
  }

  .caret {
    appearance: none;
    left: -100%;
    margin-bottom: 10px;
    position: absolute;
    top: -100%;

    &:checked {
      ~ label {
        background-image: url("../svg/caret-down.svg");
      }

      ~ .sharing-cta-content {
        max-height: 423px;
        overflow: visible;

        @include media-breakpoint-up(md) {
          max-height: 248px;
        }
      }
    }

    ~ label {
      background-image: url("../svg/caret-up.svg");
      background-repeat: no-repeat;
      height: 12px;
      margin-bottom: 0;
      width: 20px;
    }
  }
}

.drawer-cta-backdrop {
  background-color: $black-with-85-percent-opacity;
  inset: 0;
  overflow: hidden;
  position: fixed;
  z-index: $zindex-modal-backdrop;
}

.sharing-cta-sticky-container {
  background-color: $primary-brand-highlight-color;
  border-bottom: 1px solid $border-color;
  border-top: 1px solid $border-color;
  bottom: 0;
  margin-top: -1px;
  padding: 10px;
  position: sticky;
  width: 100%;
  z-index: $zindex-sticky;

  .sharing-cta-buttons {
    align-items: center;
    justify-content: space-between;

    .btn {
      flex: 0 1 calc(50% - 5px);
      font-size: 0.9375rem;
      min-width: auto;
      padding: 12px 6px;

      @media (max-width: 350px) {
        font-size: 0.75rem;
        padding: 12px 4px;

        img {
          height: 16px;
          width: 16px;
        }
      }

      &:only-child {
        flex-basis: 100%;
      }

      img {
        height: 20px;
        margin: -5px 3px 0 0;
        width: 20px;
      }
    }
  }
}
