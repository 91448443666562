.donation-checkout-container {
  .primary-container {
    margin-right: 40px;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 560px;
    }

    h2 {
      margin-bottom: 20px;
    }

    .el-input-group {
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .errorlist {
      margin-bottom: 0;
      padding-top: 0;

      li {
        &:first-child {
          margin-top: 20px;
        }
      }
    }
  }

  .secondary-container {
    margin-bottom: 20px;
    width: 100%;

    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
      width: 100%;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 335px;
    }

    .deceased-photo {
      flex: 0 0 25%;
      margin-bottom: 10px;
      margin-right: 8px;
      width: auto;

      @include media-breakpoint-up(md) {
        margin-bottom: 40px;
        margin-right: 0;
        width: 320px;
      }

      @include media-breakpoint-up(lg) {
        width: 335px;
      }

      .profile-photo {
        @include background-img-aspect-ratio(335px, 250px);

        border-radius: $border-radius-sm;
      }
    }
  }

  .donation-fine-print {
    @include media-breakpoint-between(xs, md) {
      font-size: 0.9375rem;
    }
  }

  .tip-container-inner {
    &:not(.d-none) {
      margin-top: 40px;
    }
  }

  .custom-select-with-caret {
    width: 100%;
  }

  .total-charge {
    margin-bottom: 20px;
  }

  .exp-cvc-container {
    > div {
      line-height: 1.2em;
      width: 148px;

      @include media-breakpoint-between(xs, md) {
        flex: 0 0 calc(33.33% - 14px);
        width: auto;
      }
    }
  }

  .el-checkbox {
    &.disabled {
      label {
        color: $light-gray-text;
      }

      .pseudo-checkbox {
        background-color: $light-gray;
      }
    }
  }
}

.donor-choice-charity-search-container {
  margin: 20px 0 60px;

  @include media-breakpoint-up(md) {
    margin: 20px 0;
  }

  .custom-select-with-caret {
    padding: 8px;

    @include media-breakpoint-between(xs, md) {
      min-width: auto;
      width: 100%;
    }
  }
}

.donate-to-searched-charity {
  margin: 20px 0;
}

.donor-choice-charities-container {
  margin-top: 20px;

  .donor-choice-charity-container {
    background-color: var(--primary-brand-color-light);
    border: 2px solid var(--primary-brand-color);
    border-radius: 30px;
    color: var(--primary-brand-color);
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 20px;
    padding: 5px 11px;

    &:last-child {
      margin-right: 0;
    }

    &.selected {
      background-color: var(--primary-brand-color);
      color: $white;
    }
  }

  .donor-choice-charities-select {
    margin-bottom: 20px;
    width: 100%;
  }
}

.donor-choice-charity-overview {
  margin: 20px 0;

  > div {
    margin-bottom: 40px;

    &:first-child {
      align-items: center;

      div {
        &:first-child {
          margin-right: 10px;
        }

        &:last-child {
          @extend %base-h3;

          flex-shrink: 10;
          margin-bottom: 0;
        }
      }

      img {
        height: 41px;
        width: auto;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .btn {
      width: 335px;
    }
  }
}

.donations-container {
  .no-donations-message {
    margin-bottom: 20px;
  }

  .btn-load-more {
    margin: 20px 0 0;

    @include media-breakpoint-between(xs, md) {
      width: 100%;
    }
  }

  .donation-container {
    @include el-border-bottom;

    padding: 20px 0;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border-bottom: 0;
      padding-bottom: 0;
    }

    .donation-attribution {
      .donation-name {
        @extend %base-h3;

        font-weight: $font-weight-normal;
        margin: 0;
        padding-top: 4px;
        word-break: break-word;
        word-wrap: break-word;
      }

      .donation-amount {
        @extend %base-h2;

        margin: 0;
      }
    }

    .donation-charity-info {
      margin: 10px 0;

      @include media-breakpoint-up(md) {
        margin: 10px 0 0;
      }
    }
  }
}

.donations-totals {
  .donations-totals-inner {
    div {
      &:first-child {
        @extend %base-h2;

        margin: 0 10px 0 0;
      }

      &:last-child {
        flex-shrink: 0;
      }
    }
  }
}

.donate-cta-tablet-mobile {
  background-color: $white;
  bottom: 0;
  box-shadow: -1px -1px 3px 0 $purple-with-10-percent-opacity;
  display: flex;
  padding: 10px 20px 20px;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: $zindex-fixed;

  @include media-breakpoint-up(md) {
    padding: 20px 40px;
    text-align: left;
  }

  a {
    height: 60px;
    min-width: 100%;

    @include media-breakpoint-up(md) {
      min-width: 335px;
    }
  }

  div {
    @include media-breakpoint-between(xs, md) {
      padding-bottom: 20px;
    }
  }
}

.display-name-container {
  margin-top: 16px;
}

.multi-charity-disambiguation {
  margin-bottom: 40px;

  .checkout-link-list {
    > a {
      margin-bottom: 8px;
    }
  }
}

.charity-overview-section {
  .text-note {
    margin-top: 20px;
  }
}
