@use "sass:map";

// Public memorial page

.memorial-content-container {
  @include media-breakpoint-up(lg) {
    padding: 0 40px;
  }
}

// TODO: This is private only with the exception of _editable_content_modal.html
.edit-memorial-container {
  @include media-breakpoint-up(md) {
    padding: 0 20px;
  }

  @include media-breakpoint-up(lg) {
    padding: 0 40px;
  }

  .body-card {
    &.body-card--with-mobile-border {
      border-bottom: 1px solid $medium-gray;
      margin-bottom: 40px;

      @include media-breakpoint-up(md) {
        border-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
}

// NOTE: The sole public usage of this is for memories
.templates-container {
  margin-top: 20px;

  @include media-breakpoint-up(md) {
    width: 335px;
  }

  .text-note-small {
    margin-bottom: 10px;
  }

  &.condolence-templates-container {
    margin-bottom: 40px;
    margin-top: 0;
    width: 100%;
  }
}

.memorial-password-protected-container {
  @include media-breakpoint-up(lg) {
    padding-top: 40px;
  }

  .body-card {
    max-width: 1095px;

    @include media-breakpoint-up(md) {
      margin: 0 auto;
    }

    .borderless-input {
      max-width: 100%;
      width: 100%;
    }
  }
}

.memorial-container {
  @include media-breakpoint-up(lg) {
    padding-top: 40px;
  }

  .body-card {
    margin: 0 auto;
    max-width: 1400px;
    padding: 0;

    @include media-breakpoint-only(md) {
      margin: 20px 24px;
    }
  }

  .body-card-moderation-queue {
    margin-bottom: 40px;
    padding: 20px;
    position: static;

    @include media-breakpoint-up(md) {
      padding: 40px;
      position: relative;
    }

    .remaining-review-count {
      margin-bottom: 20px;
      position: static;
      right: auto;
      top: auto;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        position: absolute;
        right: 40px;
        top: 40px;
      }
    }

    td {
      ul {
        margin-bottom: 0;
      }
    }

    .td-details {
      width: 60%;
    }

    .moderation-form {
      textarea {
        font-size: 0.975rem;
        height: 45px;
        resize: vertical;
        width: 100%;
      }

      .errorlist {
        margin-bottom: 40px;
        padding-top: 0;
      }
    }
  }
}

.deceased-info-and-nav {
  @include el-light-border-bottom;

  position: relative;

  @include media-breakpoint-up(md) {
    padding: 35px 35px 0;
  }

  @include media-breakpoint-up(lg) {
    padding: 40px 40px 0;
  }

  &.with-edit-icon {
    @include media-breakpoint-up(md) {
      padding-top: 30px;
    }
  }
}

.deceased-photo {
  flex-shrink: 0;
  position: relative;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 320px;
  }

  @include media-breakpoint-up(lg) {
    width: 425px;
  }
}

.profile-photo {
  @include background-img-aspect-ratio(425px, 319px);

  background-size: cover;
  min-width: 100%;

  @include media-breakpoint-up(md) {
    @include el-border;

    border-radius: $border-radius-sm;
  }
}

.deceased-metadata-container {
  margin: 20px;

  @include media-breakpoint-up(md) {
    margin: 0 0 0 40px;
  }

  .profile-metadata {
    margin-bottom: 0;
    margin-top: 30px;

    li {
      display: flex;
      margin-bottom: 18px;

      &:last-of-type {
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
          margin-bottom: 18px;
        }
      }

      .metadata-wrapper {
        margin-left: 18px;
        position: relative;
        top: -6px;
      }
    }

    img {
      &.metadata-icon {
        margin-right: 6px;
        width: 20px;
      }
    }
  }
}

.photo-memorial-social-module-container {
  flex-grow: 1;
  margin-right: 0 !important;

  .memorial-social-module {
    justify-content: flex-end;
    margin-top: 0;

    > a {
      flex-direction: row-reverse;
      margin-left: 10px;

      @include media-breakpoint-up(up) {
        margin-left: 15px;
      }

      img {
        margin-left: 0;
        margin-right: 4px;
      }
    }

    .header-button {
      background: none;
      border: 0;
      font-size: 0.875rem;
      line-height: 1.5rem;
      padding: 0;

      img {
        margin-right: 0;
      }

      &:active {
        border: 0;
      }
    }
  }
}

.memorial-social-module {
  @include el-light-border-bottom;

  height: 60px;
  padding: 0 20px;

  @include media-breakpoint-up(md) {
    border: 0;
    height: auto;
    margin: 20px 5px 0;
    padding: 0;
  }

  > a {
    align-items: center;
    color: $darker-gray-text;
    flex-direction: row-reverse;
    font-size: 0.875rem;
    font-weight: $font-weight-semibold;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    &:last-child {
      @include media-breakpoint-between(xs, md) {
        img {
          margin-right: 6px;
        }
      }
    }

    &:hover {
      color: $darker-gray-text;
    }

    &:not(:last-child) {
      @include media-breakpoint-up(lg) {
        margin-bottom: 20px;
      }
    }

    img {
      margin: 0 4px 0 0;

      @include media-breakpoint-up(lg) {
        margin: 0 0 0 10px;
      }
    }
  }
}

.memorial-detail-section {
  @include media-breakpoint-up(lg) {
    padding: 0 40px;
  }

  .subtitle {
    margin-bottom: 0;
  }

  .private-event-zoom-details {
    margin-left: 15px;
    margin-top: 10px;

    .text-note {
      margin-bottom: 5px;
    }
  }
}

.memorial-primary-section {
  width: 100%;

  @include media-breakpoint-up(lg) {
    margin-right: 100px;
    width: 560px;
  }

  p {
    @include media-breakpoint-between(xs, md) {
      font-size: 1.125rem;
    }
  }

  .inline-mini-memorial-action-cta {
    @include el-light-border-bottom;

    padding: 20px;

    @include media-breakpoint-up(md) {
      padding: 20px 40px;
    }

    @include media-breakpoint-up(lg) {
      padding: 40px 0;
    }

    .inline-cta-copy {
      width: 100%;
    }

    > a {
      &.fake-form-field-wrapper {
        margin-right: 10px;
        width: 100%;

        @include media-breakpoint-up(md) {
          margin-right: 20px;
        }
      }

      div {
        border-radius: $border-radius-lg;
        font-weight: $font-weight-normal;
        height: 40px;
        padding: 8px;

        @include media-breakpoint-up(md) {
          padding: 8px 15px;
        }
      }
    }

    .share-condolence-fake-field {
      @include el-border;
    }

    .small-btn-with-icon {
      border: 1px solid;
      font-size: 0.875rem;
      width: 80px;

      @include media-breakpoint-up(md) {
        width: 103px;
      }
    }
  }

  .rsvp-inline-cta {
    .small-btn-with-icon {
      margin-left: 20px;
      width: 100px;

      @include media-breakpoint-up(md) {
        margin-left: 0;
        width: 119px;
      }
    }
  }

  .memorial-subscription-section-container {
    form {
      margin-top: 20px;
    }
  }

  .subscription-cta {
    width: 100%;

    .btn-form-submit {
      margin-top: 40px;
      text-align: center;

      @include media-breakpoint-up(md) {
        text-align: left;
      }

      .btn {
        @include media-breakpoint-between(xs, md) {
          width: 100%;
        }
      }

      &.authenticated {
        margin-top: 0;
      }
    }
  }

  .obituary-rsvp-inline-cta-wrapper {
    padding-bottom: 0;
  }

  .business-faq-container {
    li {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .story-contribute-link {
    display: block;
    margin-top: 20px;
  }
}

.profile-section-details,
.column-section {
  .column-section__donations-totals {
    padding-top: 20px;

    .column-section__totals-wrapper {
      align-items: flex-start;
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(lg) {
        align-items: center;
      }

      .column-section__total-amount {
        font-size: 1.75rem;
        font-weight: $font-weight-semibold;
        line-height: 1.5;
        margin: 0 10px 0 0;
      }

      .column-section__total-donors {
        flex-shrink: 0;
      }
    }
  }

  .column-section__goal {
    margin-bottom: 16px;
    padding-top: 20px;

    .d-flex {
      align-items: baseline;
      flex-wrap: wrap;

      div {
        &:first-child {
          @extend %base-h2;

          margin: 0 10px 0 0;
        }

        &:last-child {
          flex-shrink: 0;
        }
      }
    }
  }

  // ----- Individual modules -----
  // Fundraising sidebar module
  &.column-section__fundraising {
    &.column-section--condensed {
      .column-section__header {
        display: none;
      }

      .column-section__subheader {
        margin-bottom: 10px;
      }

      .column-section__divider--wide {
        display: none;
      }
    }
  }

  // Sharing sidebar module
  &.column-section__sharing {
    position: sticky;
    top: -20px;

    .sharing-section__buttons {
      display: flex;
      justify-content: space-between;

      > .btn {
        flex: 0 0 calc(33.33% - 2.666px);
        margin: 0 0 20px;
        min-width: auto;

        img {
          margin-right: 4px;
          position: relative;
          top: -2px;
        }

        .copy-cta-text {
          flex-shrink: 0;
        }

        .text-note-small {
          margin-top: 2px;
        }

        &.small-button {
          margin: 0;
        }
      }
    }
  }

  // Mini memories module
  &.column-section__memories {
    .column-section__header {
      h2 {
        font-size: 1.5rem;
      }
    }

    .column-section__objects {
      margin-top: 20px;
    }
  }

  // Donate page CTA module
  &.column-section__donate-page-cta {
    @include el-light-border-bottom;

    padding-left: 20px;
    padding-right: 20px;

    @include media-breakpoint-up(lg) {
      border-bottom: 0;
    }

    .btn {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 335px;
      }
    }

    .sharing-btn {
      align-items: center;
      justify-content: space-between;
      margin-left: 0;
      margin-right: 0;

      @include media-breakpoint-up(md) {
        align-items: flex-start;
        justify-content: flex-start;
      }
    }

    .donate-page-cta__btns {
      margin-top: 20px;
    }

    .donate-cta {
      flex-direction: column;

      .btn {
        margin: 6px 0 30px;
      }
    }

    .cause-share-link-cta {
      flex-direction: column;
      margin-bottom: 20px;
      margin-top: 10px;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        margin-top: 40px;
      }
    }

    .donations-finished-note {
      margin: 20px 0 40px;

      span {
        @extend %base-h2;
      }
    }

    .donations-totals {
      margin: 20px 0 40px;

      &.hidden-donation-total {
        margin: 0 0 16px;
      }
    }
  }

  // Public Subscribers content module
  &.column-section__subscribers {
    .public-subscribers-list {
      > div {
        align-items: flex-start;
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
          width: 50%;
        }

        img {
          border-radius: $border-radius-xl;
          margin-right: 10px;

          &.no-border-radius {
            border-radius: 0;
          }
        }
      }

      .load-more-subscribers {
        button {
          width: 100%;

          @include media-breakpoint-up(md) {
            width: auto;
          }
        }
      }
    }

    .add-yourself {
      margin-bottom: 20px;
    }

    .btn-link {
      min-width: auto;
      padding: 0;
    }

    .column-section__header {
      margin-bottom: 20px;
    }

    form {
      .btn-link {
        margin-bottom: 0;
        margin-left: 0;
        padding-bottom: 0;
        padding-left: 0;
        text-align: left;
      }
    }
  }

  // Other Key Details
  &.column-section__key-details {
    .text-note {
      padding-top: 10px;
    }

    div {
      word-break: break-word;
      word-wrap: break-word;

      &.main-edit-controls {
        word-break: normal;
      }
    }
  }

  // Print Obituary
  &.column-section__print-obituary {
    &:last-of-type {
      @include media-breakpoint-between(xs, md) {
        border-bottom: 0;
      }
    }
  }
}

.profile-section-details {
  @include media-breakpoint-up(lg) {
    padding: 40px 0;
  }
}

.other-key-details-cards {
  .other-key-detail-card {
    border-radius: $border-radius-lg;
    box-shadow: $card-shadow;
    margin-bottom: 10px;

    .card-content {
      padding: 20px;

      h3 {
        font-weight: $font-weight-normal;
        margin: 0;
      }
    }

    .card-photo {
      display: block;
      height: 0;
      padding-top: 65%;
      position: relative;
      width: 100%;

      img {
        object-fit: cover;
      }

      img,
      iframe {
        border-top-left-radius: $border-radius-lg;
        border-top-right-radius: $border-radius-lg;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }
}

.flower-order-details-cards {
  margin-bottom: 20px;

  &::after {
    content: "";
    width: 100%;

    @include media-breakpoint-up(md) {
      width: calc(33% - 6.667px);
    }
  }

  .flower-order-detail-card {
    border-radius: $border-radius-lg;
    box-shadow: $card-shadow;
    margin-bottom: 10px;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: calc(33% - 6.667px);
    }

    .card-content {
      display: flex;
      justify-content: center;
      padding: 20px;
    }

    .card-photo-container {
      display: block;
      height: 0;
      padding-top: 65%;
      position: relative;
      width: 100%;

      .card-photo-img {
        background-position: center;
        border-top-left-radius: $border-radius-lg;
        border-top-right-radius: $border-radius-lg;
        height: 100%;
        left: 0;
        max-height: 250px;
        object-fit: contain;
        padding-top: 10px;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }
}

// stylelint-disable-next-line no-duplicate-selectors
.profile-section-details {
  &.private-profile-section-details {
    @include media-breakpoint-between(xs, md) {
      padding: 0;
    }
  }

  &.memorial-updates-section,
  &.personal-note-section,
  &.obituary-profile-section {
    word-break: break-word;
    word-wrap: break-word;
  }

  .background-note {
    margin-top: 40px;
  }

  .public-related {
    .mini-cta {
      margin: 40px 0 20px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    .public-related-list {
      > div {
        align-items: flex-start;
        display: flex;
        flex-basis: 100%;

        @include media-breakpoint-up(md) {
          flex-basis: 50%;
        }

        &:not(:nth-of-type(-n + 2)) {
          margin-top: 20px;
        }

        &:not(:first-of-type) {
          @include media-breakpoint-between(xs, md) {
            margin-top: 20px;
          }
        }

        > img {
          border-radius: 18px;
          margin: 4px 10px 0 0;

          &.memorial-thumb {
            height: 36px;
            object-fit: cover;
            object-position: center;
            width: 36px;
          }
        }

        .name-and-relationship {
          font-size: 0.9735rem;

          .create-link {
            font-size: 0.875rem;
          }

          .text-note-small {
            line-height: 0.875rem;
          }
        }
      }
    }
  }
}

.memorial-updates-section {
  .memorial-update-contents {
    margin-top: 30px;

    &:first-of-type {
      margin-top: 0;
    }

    h3 {
      margin-bottom: 10px;
    }
  }
}

.event-metadata {
  margin-top: 13px;

  @include media-breakpoint-between(xs, md) {
    font-size: 1.125rem;
    margin-bottom: 0;
  }

  .event-description {
    display: flex;
    word-break: break-word;
    word-wrap: break-word;

    .icon-text {
      margin-right: 14px;
      margin-top: 4px;
    }

    .icon-exit {
      margin-right: 19px;
    }

    div {
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .rsvp {
    .attending {
      margin-bottom: -36px;
    }
  }

  .zoom-details {
    .text-note {
      margin-bottom: 10px;
    }
  }

  li {
    word-break: break-word;
    word-wrap: break-word;

    &.event-program-attachment {
      margin-top: 20px;
      width: 100%;

      @include media-breakpoint-up(md) {
        margin-bottom: 20px;
      }

      .event-program-image {
        background-position: center left;
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: $border-radius-lg;
        flex: 0 0 calc(50% - 5px);
        height: 0;
        padding-top: 75%;
        position: relative;

        @include media-breakpoint-up(md) {
          @include background-img-aspect-ratio(450px, 150px);

          margin-bottom: 10px;
        }
      }
    }
  }

  img {
    &.metadata-icon {
      bottom: -2px;
      position: relative;
      width: 20px;

      @include media-breakpoint-up(md) {
        margin-right: 10px;
      }

      &.push-down {
        @include media-breakpoint-up(md) {
          top: 5px;
        }
      }
    }

    &.tulip-icon {
      margin-top: -4px;
    }
  }

  span {
    &.rsvp-stat {
      margin-right: 25px;
    }
  }
}

.location-address {
  display: block;
}

.body-card-report-footer {
  margin-top: -1px;
  padding: 20px 20px 0;

  @include media-breakpoint-up(md) {
    @include el-light-border-top;

    padding: 40px 40px 20px;
  }

  @include media-breakpoint-up(lg) {
    padding: 40px;
  }

  .text-note {
    padding-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-right: 20px;
      padding-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      margin-right: 40px;
    }
  }

  .report-cta {
    flex-shrink: 0;

    img {
      height: 20px;
      width: 20px;
    }
  }
}

.unpublish,
.report-content {
  margin-top: 40px;

  .errorlist {
    padding-top: 10px;
  }

  .btn-form-submit {
    @include media-breakpoint-between(xs, md) {
      padding: 0 20px;
    }
  }

  .header-information {
    margin: 20px;

    @include media-breakpoint-up(md) {
      margin: 0;
    }

    h1 {
      font-size: 2rem;
      margin-bottom: 10px;
    }
  }

  .body-card {
    .reason-wrapper {
      align-items: flex-start;
      flex-direction: column;
    }

    .reason-selection-wrapper {
      .el-radio-btn {
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 20px;
        }
      }
    }

    .other-reason {
      height: 180px;
      padding: 20px;

      @include media-breakpoint-up(md) {
        height: 120px;
      }

      &::placeholder {
        color: $lighter-gray-text;
      }
    }

    label {
      align-items: center;
      cursor: pointer;
    }
  }

  .information {
    padding: 20px;

    @include media-breakpoint-up(md) {
      padding: 0 0 40px;
    }
  }

  .other-reason-label {
    margin-bottom: 10px;
    padding-top: 20px;
  }
}

.reporting-body-card {
  margin-top: 20px;

  > div {
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: 70%;
    }
  }

  .dark-gray-label {
    font-size: 1.25rem;
    font-weight: $font-weight-semibold;
    text-transform: none;
  }

  .identity-container {
    .el-input-group {
      > div {
        flex: 0 0 calc(50% - 4px);
      }
    }
  }

  .creator-known-container {
    .el-radio-btn {
      margin-right: 16px;

      label {
        .radio-label {
          margin-left: 0;
          padding-left: 8px;
        }
      }
    }
  }

  .description-container {
    margin-bottom: 40px;

    .errorlist {
      margin-top: 14px;
    }
  }
}

.content-report-thank-you {
  @include media-breakpoint-between(xs, md) {
    margin: 20px;
  }

  .report-thank-you {
    h1 {
      margin-bottom: 0;

      @include media-breakpoint-up(md) {
        padding-top: 40px;
      }
    }

    div {
      padding: 20px 0;
    }

    ul {
      line-height: 40px;
    }
  }
}

.memorial-notifications-modal {
  .btn {
    margin: 0;
    min-width: 48.5%;
  }
}

.modal-unedited-template {
  .btn {
    min-width: 100%;

    @include media-breakpoint-up(md) {
      min-width: calc(50% - 8px);
    }
  }
}

.user-profile-photo {
  &.family-member-photo {
    height: 30px;
    min-width: 30px;
    object-fit: cover;
    width: 30px;
  }
}

.create-related-form {
  margin: 20px 0;

  .borderless-input {
    max-width: 100%;
    width: 100%;
  }

  .close-button {
    align-items: center;
    color: $gray-600;
    display: flex;
    float: right;
    font-size: 0.9375rem;
    margin: 0 0 10px 10px;
    text-transform: uppercase;

    span {
      font-size: 1.25rem;
      margin-bottom: 1px;
      margin-right: 4px;
    }
  }

  .name-entry {
    > input {
      flex: 0 1 calc(50% - 10px);
    }
  }
}

.survivor-invite-manager {
  margin-bottom: 20px;

  label {
    font-size: 0.9375rem;
    margin-bottom: 0;
  }
}

.survivor-invite-manager-disclaimer {
  color: $dark-gray-text;
  margin-bottom: 20px;
}

.memorial-search-input-group {
  .memorial-search-result-container {
    display: flex;

    .clear-search-result {
      align-self: center;
      font-size: 1.5rem;
      margin: 0 10px 0 20px;
    }

    .memorial-image {
      border-radius: $border-radius-sm;
      height: 60px;
      margin-right: 10px;
      width: 80px;
    }

    .years {
      justify-self: flex-end;
    }
  }

  .memorial-search-error,
  .memorial-search-duplicate,
  .memorial-search-ineligible {
    color: $red;
  }

  .name-entry {
    > input {
      flex: 0 1 calc(50% - 10px);
    }
  }
}

.timeline-container {
  &.edit-memorial-content {
    .body-card {
      margin-bottom: 40px;

      &.add-milestone-container {
        @include media-breakpoint-between(xs, md) {
          margin-top: 20px;
          padding: 20px;
        }
      }
    }
  }

  > .d-grid {
    .btn {
      margin: 20px 0;
    }
  }

  .add-milestone-container {
    border: 1px solid $gray-300;
    border-radius: $border-radius-lg;
    margin-bottom: 40px;
    margin-top: 20px;
    padding: 20px;

    .clear-person-selection {
      font-size: 0.875rem;
    }

    .milestone-edit-read-only {
      align-items: center;
      background-color: $gray-100;
      border-radius: $border-radius-lg;
      display: flex;
      margin-bottom: 20px;
      padding: 20px;

      .milestone-icon {
        align-items: center;
        border: 3px solid;
        border-radius: 50%;
        display: flex;
        flex-shrink: 0;
        height: 50px;
        justify-content: space-around;
        margin-right: 16px;
        width: 50px;

        img {
          height: 30px;
          object-fit: contain;
          width: 30px;
        }
      }

      h3 {
        margin-bottom: 0;
      }

      img {
        height: 16px;
        margin-bottom: 4px;
        width: 16px;
      }
    }

    .milestone-person-create {
      .tab-content {
        margin-top: 20px;
      }
    }

    .submit-container {
      .btn {
        flex-basis: auto;
        margin: 8px 0 0;
        width: 100%;

        @include media-breakpoint-up(md) {
          flex-basis: calc(50% - 6px);
          margin: 10px 0;
          width: auto;
        }
      }
    }

    .borderless-input {
      max-width: 100%;
      width: 100%;
    }

    .select-date-widget {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }

      > div {
        background-size: 18px 11px;
        flex-basis: 25%;
        flex-shrink: 0;

        @include media-breakpoint-between(xs, md) {
          background-position: right 10px center;
        }

        &:first-child {
          flex-grow: 1;
        }

        &:not(:last-child) {
          margin-right: 10px;

          @include media-breakpoint-between(xs, md) {
            margin-bottom: 10px;
            margin-right: 0;
          }
        }

        select {
          font-weight: $font-weight-normal;

          @include media-breakpoint-between(xs, md) {
            padding: 8px 10px;
          }
        }
      }
    }
  }

  .timeline-nav-container {
    font-size: 0.875rem;
    height: 100%;
    margin-left: 10px;
    position: sticky;
    top: 20px;

    &.manager-public-timeline {
      top: 145px;
    }

    .timeline-nav {
      border: 1px solid $gray-300;
      border-radius: $border-radius-lg;

      .nav-link {
        color: $gray-300;
        display: block;

        &.active {
          color: var(--primary-brand-color);
          font-weight: $font-weight-semibold;
        }
      }
    }

    .add-milestone-button {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      text-align: center;

      .plus-button {
        background-color: $white;
        border: 1px solid;
        border-radius: 50%;
        font-size: 25px;
        height: 40px;
        line-height: 38px;
        transition:
          background-color 0.2s ease-in-out,
          color 0.2s ease-in-out;
        width: 40px;
      }

      .button-text {
        font-size: 0.75rem;
        margin-top: 10px;
        text-transform: uppercase;
      }

      &:hover {
        .plus-button {
          color: $white;
        }
      }
    }
  }
}

.timeline {
  flex-grow: 1;

  .timeline-year-group {
    border-left: 4px solid $gray-300;
    margin-left: 33px;
    padding: 0 0 40px 33px;

    &:last-of-type {
      padding: 0 0 0 33px;

      .timeline-milestone {
        &:last-of-type {
          background-color: $white;
        }
      }
    }

    .timeline-year-label {
      background-color: $very-light-gray;
      border: 2px solid $gray-300;
      border-radius: 50% / 100%;
      color: $dark-gray-text;
      display: inline-block;
      margin-left: -70px;
      text-align: center;
      text-transform: capitalize;
      width: 70px;
    }
  }

  .timeline-milestone {
    display: flex;
    margin: 20px 0 20px -60px;

    &:last-of-type {
      margin: 10px 0 0 -60px;
    }

    &.hidden {
      opacity: 0.8;

      .milestone-content {
        border: 2px dashed $gray-500;
        border-radius: $border-radius-lg;
        left: -10px;
        margin-right: -10px;
        padding: 10px;
        position: relative;

        @include media-breakpoint-up(md) {
          margin-right: 0;
        }

        .milestone-admin-controls {
          margin-bottom: 0 !important;
        }

        .hidden-label {
          color: $gray-500;
          font-size: 0.875rem;
          padding: 0 0 8px;
          text-transform: uppercase;

          @include media-breakpoint-up(md) {
            padding: 10px;
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }
    }

    .milestone-icon {
      align-items: center;
      border: 3px solid;
      border-radius: 50%;
      display: flex;
      flex-shrink: 0;
      height: 50px;
      justify-content: space-around;
      width: 50px;

      img {
        height: 30px;
        object-fit: contain;
        width: 30px;
      }
    }

    .milestone-content {
      margin-left: 20px;
      width: 100%;

      h3 {
        margin: 0;
      }

      .milestone-photo {
        border: 1px solid $gray-500;
        border-radius: $border-radius-lg;
        margin: 10px 10px 10px 0;
        width: 100%;

        @include media-breakpoint-up(md) {
          width: calc(100% - 20px);
        }
      }

      .milestone-meta {
        color: $light-gray-text;
        font-size: 0.9375rem;
        width: 100%;

        .milestone-admin-controls {
          @include media-breakpoint-between(xs, md) {
            margin: 20px 0;
          }

          a,
          button {
            @include media-breakpoint-between(xs, md) {
              border: 1px solid;
              border-radius: $border-radius-lg;
              flex: 0 0 calc(33.33% - 10px);
              justify-content: center;
              margin-right: 4px;
              padding: 6px 10px;
              width: 100%;
            }
          }
        }

        > div,
        a,
        .milestone-admin-control {
          align-items: flex-start;
          display: flex;

          @include media-breakpoint-up(md) {
            margin: 0 4px;
          }

          > img {
            height: 16px;
            margin-right: 5px;
            margin-top: 3px;
            width: 16px;

            &.pin {
              margin-left: 1px;
            }
          }
        }

        .milestone-admin-control {
          font-size: 0.9375rem;
          line-height: 1.5;
          max-width: none;
          min-width: auto;
          padding: 0;
          width: auto;
        }

        .milestone-user-photo {
          min-width: 0;
        }
      }
    }
  }
}

.mini-timeline-container {
  .timeline {
    border-left: 4px solid $gray-300;
    margin: 0 0 40px 33px;
    padding-left: 33px;

    &.has-more {
      border-left-style: dashed;

      .timeline-milestone {
        &:last-of-type {
          background: none;
        }
      }
    }

    .timeline-milestone {
      &:last-of-type {
        background-color: $white;
        margin-bottom: 0;
      }
    }
  }
}

.favorite {
  .user-post-container {
    margin-top: 0;

    .editable-user-post {
      &:first-of-type {
        padding-top: 0;
      }
    }
  }

  .prompt {
    align-items: flex-start;
    display: flex;
    margin-bottom: 25px;

    img {
      display: inline-block;
      height: 36px;
      margin-right: 6px;
      object-position: center bottom;
      width: 36px;
    }

    div {
      margin-top: 2px;
    }
  }
}

.modal-block-user {
  .modal-body {
    .user-to-block-name {
      font-weight: $font-weight-semibold;
    }

    .submit-container {
      .btn {
        flex-basis: calc(50% - 4px);
        min-width: auto;
        width: auto;
      }
    }
  }
}

.recently-created-container {
  max-height: 1000px;
  transition: max-height 0.5s ease-in-out;

  &.dismissed {
    max-height: 0;
    overflow: hidden;
  }

  .recent-memorial-body-card {
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
    }

    .deceased-info-and-nav {
      border-bottom: 0;

      .profile-photo {
        @include media-breakpoint-between(xs, md) {
          border-radius: $border-radius-lg;
        }
      }
    }
  }

  .buttons-row {
    margin-bottom: 40px;

    .btn {
      margin-right: 0;

      @include media-breakpoint-between(xs, md) {
        margin: 0 0 10px;
      }
    }
  }
}

.example-memorial-mobile-banner {
  @include el-border-top;

  background-color: $white;
  bottom: 0;
  box-shadow: -1px -1px 3px 0 $purple-with-10-percent-opacity;
  padding: 20px;
  position: fixed;
  z-index: $zindex-fixed;

  &.inaccurate-memorial {
    background-color: $secondary-brand-color;
    color: $white;
  }

  .btn {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 20px 0 0;
    width: 100%;

    img {
      height: 16px;
      margin-left: 8px;
      width: 18px;
    }
  }
}

.photo-header-slides-container {
  margin: 10px 0 0;
  position: relative;

  .glide__slide {
    height: auto;

    .photo-header-slide,
    img {
      height: calc(100% - 2px);
    }

    &:only-child {
      img {
        border-radius: 0 !important;
      }
    }
  }

  .photo-header-slide {
    padding: 20px;

    .column-section__subheader {
      white-space: normal;
    }
  }
}

.modal-example {
  p {
    padding: 10px;
  }

  .btn {
    margin: 20px 0 0;
    width: 100%;
  }
}

.example-callout {
  background-color: $blue-gray-background;
  border-radius: $border-radius-lg;
  margin-bottom: 20px;
  padding: 20px;

  .btn {
    margin: 10px 0;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: auto;
    }

    img {
      height: 14px;
      left: 2px;
      position: relative;
      top: -2px;
      width: 18px;
    }
  }
}

.deceased-photo-collage-container {
  .deceased-photo-collage-name-and-metadata {
    @include media-breakpoint-only(md) {
      font-size: 0.9375rem;
    }

    h2 {
      margin-bottom: 0;

      @include media-breakpoint-only(md) {
        font-size: 1.625rem;
      }
    }

    .location-and-dates {
      > a,
      > div {
        margin-right: 16px;

        &.spacer {
          &::before {
            content: "•";
          }
        }
      }
    }
  }
}

.deceased-photo-collage {
  background-color: $white;

  .collage-edit-icon {
    height: 20px;
    width: 20px;
  }
}

.post-action-container {
  margin-top: 40px;

  .share-cta-container {
    .sharing-btn-container {
      flex: 0 0 calc(50% - 8px);
      min-width: 0;

      @include media-breakpoint-between(xs, md) {
        margin-bottom: 30px;
      }

      &:last-of-type {
        @include media-breakpoint-between(xs, md) {
          margin-bottom: 0;
        }
      }

      .sharing-btn-label {
        font-size: 0.9375rem;
        margin-bottom: 10px;
        text-align: center;
      }

      .copy-cta-text {
        white-space: nowrap;
      }

      .sharing-url {
        align-self: center;
      }
    }
  }

  .post-action-content-container {
    background-color: $white;
    margin-right: 0;
    padding: 0 20px;
    width: 100%;

    @include media-breakpoint-up(md) {
      border-radius: $border-radius-lg;
      box-shadow: $card-shadow;
      padding: 20px;
      width: 650px;
    }

    .profile-section-details {
      @include media-breakpoint-between(xs, md) {
        padding: 0;
      }
    }

    .text-input-label {
      margin-left: 25px;
      text-align: left;
    }

    .dark-gray-label {
      margin-left: 0;
      text-align: left;
    }

    .post-action-section {
      @include media-breakpoint-up(md) {
        margin-top: 40px;
      }

      &:first-of-type {
        border-bottom: 1px solid $line-divider-color;
        padding-bottom: 30px;
      }

      &:last-of-type {
        border-top: 1px solid $line-divider-color;
        padding-top: 40px;

        @include media-breakpoint-up(md) {
          padding-bottom: 10px;
        }
      }

      &.post-action-continue-container {
        @include media-breakpoint-between(xs, md) {
          margin-top: 40px;
        }
      }

      .img--full-width {
        width: 100%;
      }
    }

    .action-confirmation-container {
      text-align: center;

      h1 {
        margin: 20px 0;
      }
    }
  }

  .action-share-ideas-container {
    text-align: center;

    @include media-breakpoint-between(xs, md) {
      padding: 20px 0 40px;
    }
  }

  .action-share-icons-container {
    .action-share-icon-container {
      font-size: 0.875rem;
      width: 50%;

      &:nth-of-type(-n + 2) {
        @include media-breakpoint-between(xs, md) {
          margin-bottom: 20px;
        }
      }

      @include media-breakpoint-up(md) {
        width: 25%;
      }
    }
  }
}

.memorial-search-container {
  @include el-light-border-bottom;
  @include el-light-border-top;

  padding: 20px !important;

  @include media-breakpoint-up(md) {
    border-top: 0;
    padding: 30px 40px !important;
  }

  .memorial-search-input {
    flex-grow: 1;
    margin-right: 20px;

    @include media-breakpoint-up(md) {
      margin-right: 40px;
    }
  }

  .borderless-input {
    font-size: 1.3125rem;
    height: 2.5rem;
    margin: 9px 20px 0 0;
    width: 100%;
  }

  .btn {
    @include media-breakpoint-between(xs, md) {
      min-width: 58px;
    }

    @include media-breakpoint-only(md) {
      min-width: 168px;
    }
  }
}

.memorial-search-cta-container {
  @include el-light-border-bottom;

  padding: 20px;

  @include media-breakpoint-up(md) {
    border-bottom: 0;
    padding: 20px 40px;
  }
}

// NOTE: This is used in the checklist and the header though a memorial needs to be in
// context for the header
.public-manager-share {
  margin: 2px 0 0;

  @include media-breakpoint-up(md) {
    margin: 6px 0 20px;
  }

  > a {
    color: $white;
    flex-basis: calc(33.33% - 2.667px);

    &.btn {
      margin-left: 0;
      margin-right: 0;
      min-width: auto;
    }

    img {
      margin-right: 4px;
      position: relative;
      top: -2px;
    }
  }
}

.help-out-needs-container {
  margin: 20px 0;

  .help-out-need {
    border: 1px solid $border-color;
    border-radius: $border-radius-lg;
    margin-bottom: 20px;
    padding: 20px;
  }
}

.help-out-need-volunteers {
  .need-volunteer {
    margin-right: -10px;

    &:hover {
      z-index: $zindex-default;
    }

    .user-profile-photo {
      border: 2px solid;
      height: 40px;
      margin: 0;
      width: 40px;
    }
  }
}

.need-volunteer-button {
  border-radius: 20px;
  border-width: 1.5px;
  height: 40px;
  margin: 10px 0;
  min-width: auto;
  padding: 6px 20px;
}

.need-volunteer-modal {
  .sharing-btn {
    align-items: center;
    color: $white;
    display: flex;
    justify-content: center;

    &.btn-copy {
      justify-content: space-between;
    }

    img {
      margin: -1px 6px 0 0;
    }
  }

  .next {
    margin-top: 10px;
  }

  .social-share,
  .personal-share {
    .btn-copy {
      width: 100%;
    }
  }

  .passwordless-signup-container {
    button {
      margin: 0 0 10px;
    }
  }
}

.public-needs-summary {
  .body-text-large {
    margin-bottom: 10px;
  }
}

// TODO: Does this need to be global?
.tribute-container {
  ul {
    padding-left: 15px;
    padding-top: 10px;

    .user-mention-option {
      background-color: $table-gray;
      border: 1px solid $table-border-gray;
      cursor: pointer;
      list-style: none;
      padding: 8px;

      &:first-of-type {
        border-top-left-radius: $border-radius-lg;
        border-top-right-radius: $border-radius-lg;
      }

      &:last-of-type {
        border-bottom-left-radius: $border-radius-lg;
        border-bottom-right-radius: $border-radius-lg;
      }

      &.user-mention-highlight {
        background-color: $table-hover-gray;
      }
    }
  }
}

.modal-user-mention {
  .btn {
    min-width: calc(50% - 8px);
  }
}

.notify-user-mention-button {
  input {
    &:invalid {
      border-bottom-color: $red;
    }
  }
}

.add-content {
  &:not(.btn) {
    color: $secondary-brand-color;

    &:hover {
      color: $secondary-brand-color;
    }

    &.primary {
      color: $primary-brand-color;

      &:hover {
        color: $primary-brand-color;
      }
    }
  }

  img {
    height: 18px;
    margin-top: -2px;
    width: 18px;
  }
}

.edit-content-modal {
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;

  .modal-dialog {
    @include media-breakpoint-between(xs, md) {
      margin: 0;
    }

    .modal-content {
      @include media-breakpoint-between(xs, md) {
        border: 0;
        border-radius: 0;
      }
    }
  }

  button {
    margin: 20px 0 10px;
  }

  .passwordless-signup-container {
    border-top: 1px solid $border-color;
    margin-top: 10px;
    padding-top: 10px;
  }

  .edit-memorial-container {
    padding: 0;
  }
}

.editable-content-form {
  .profile-upload-container {
    height: auto;
    width: auto;
  }

  .image-preview {
    height: auto;
    max-width: 100%;
    min-width: 100%;
    width: auto;
  }
}

.memorial-events-comment-section {
  .comment-container {
    margin-left: 0;
  }

  .user-post-container {
    .answer-text {
      @include media-breakpoint-between(xs, md) {
        font-size: 0.9375rem;
        margin-top: 0;
      }
    }

    .user-post-form {
      @include media-breakpoint-between(xs, md) {
        margin-bottom: 0;
      }
    }
  }
}

.share-obituary-section {
  margin-top: 40px;

  .btn {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
  }

  .btn-native-share {
    img {
      margin-right: 6px;
      position: relative;
      top: -2px;
    }
  }
}

.event-program-text {
  width: calc(100% - 20px);

  @include media-breakpoint-up(md) {
    width: 100%;
  }
}

.event-text-container {
  border: 1px solid;
  border-radius: $border-radius-lg;
  font-family: "Gelasio", serif;
  font-style: italic;
  line-height: 2.125rem;
  margin: 20px 0;
  padding: 20px;
}

.event-speech-form {
  @include el-border;

  border-radius: $border-radius-lg;
  margin: 20px 0 0;
  padding: 20px;

  .borderless-input {
    min-width: 0;
    width: 100%;
  }

  .speech-type-select {
    margin-bottom: 10px;
  }

  .btn {
    &.small-button {
      margin: 8px 0 0;
    }
  }
}

.event-speeches-container {
  margin-top: 20px;
  width: 100%;

  .event-speaker-speech {
    margin-top: 5px;
  }
}

.modal-message-managers {
  .message-managers--button-container {
    margin-top: 20px;

    .btn {
      min-width: 100%;

      @include media-breakpoint-up(md) {
        min-width: calc(50% - 8px);
      }
    }
  }
}

.mini-content-objects {
  .mini-content-objects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &.sidebar-objects-container {
      flex-direction: column;
      margin-bottom: 20px;

      .mini-content-object {
        background-size: contain;

        &.photo {
          min-height: 250px;
        }
      }
    }

    .mini-content-object {
      border-radius: $border-radius-lg;
      box-shadow: $card-shadow;
      cursor: pointer;
      flex: 0 0 calc(50% - 5px);
      margin-bottom: 10px;
      transition: box-shadow 0.2s ease-in-out;

      &.aspect {
        @include media-breakpoint-up(md) {
          height: 0;
          padding-top: 38%; // Approximate 4:3
          position: relative;
        }

        > div {
          left: 0;
          top: 0;

          @include media-breakpoint-up(md) {
            height: 100%;
            position: absolute;
            width: 100%;
          }
        }
      }

      @include media-breakpoint-between(xs, md) {
        flex-basis: 100%;
        flex-direction: column;
        height: auto;
        padding-top: 0;
        position: static;
        width: 100%;
      }

      &:hover {
        box-shadow: $card-shadow-hover;
      }

      &.photo {
        @include media-breakpoint-between(xs, md) {
          height: auto;
          padding-top: 0;
          position: static;
        }

        img {
          border-radius: $border-radius-lg;
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      &:not(.photo) {
        > div {
          color: $light-gray-text;
          font-family: "Gelasio", serif;
          font-size: 1.375rem;
          font-style: italic;
          font-weight: $font-weight-normal;
          hyphens: auto;
          max-width: 100%;
          overflow-y: hidden;
          padding: 16px;
          word-break: break-word;
          word-wrap: break-word;

          @include media-breakpoint-up(lg) {
            font-size: 1.25rem;
          }

          .mini-memories-text {
            .memory-text-container {
              ol,
              ul {
                overflow: hidden;
              }

              @include media-breakpoint-between(xs, md) {
                > p {
                  font-size: 1.375rem;
                }
              }
            }
          }

          .mini-favorite-question-container {
            align-items: center;
            display: flex;
            margin-bottom: 10px;
          }

          .mini-favorite-question {
            color: $dark-gray-text;
            font-size: 1.325rem;
            text-align: left;

            @include media-breakpoint-up(md) {
              font-size: 1.125rem;
            }

            @include media-breakpoint-up(lg) {
              font-size: 1.325rem;
            }
          }

          .mini-favorite-answer {
            color: $lighter-gray-text;
            text-align: center;
          }

          .mini-content-object-picture {
            float: left;
            height: 55px;
            margin: 0 10px 4px 0;
            width: 55px;

            &.favorite-icon {
              filter: $svg-recolor-dark-gray-text;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.fundraising-sidebar-cta-donations {
  &:not(.public-about-section-donations) {
    border: 0;
    margin-top: 0;
    padding-top: 0;
    width: 100%;

    @include media-breakpoint-up(lg) {
      @include light-gray-border-top;

      margin-top: 40px;
      padding-top: 40px;
    }
  }

  &.strip-last-divider {
    .fundraising-sidebar-cta-donation {
      &:last-of-type {
        border-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  .fundraising-sidebar-cta-donation {
    @include el-border-bottom;

    padding: 15px 0;

    &.informational-donation {
      padding: 20px 0;
    }

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border-bottom: 0;
      margin-bottom: 20px;
    }

    .donation-highlight {
      font-size: 1.3125rem;
      line-height: 1.5;
      padding-top: 4px;
    }

    .donation-name {
      margin: 0 0 10px;
      word-break: break-word;
      word-wrap: break-word;
    }

    .donation-amount {
      float: right;
      margin: 0 0 0 10px;
      text-align: right;
    }

    .donation-charity-info {
      margin-bottom: 10px;
    }
  }

  .column-section__header {
    @include media-breakpoint-only(md) {
      margin-top: 20px;
    }

    @include media-breakpoint-between(xs, md) {
      margin-top: 0;
    }
  }
}

.memorial-collage-grid {
  --memorial-collage-radius: #{$border-radius-lg};
  --memorial-collage-grid-gap: #{map.get($spacers, 1)};

  grid-gap: var(--memorial-collage-grid-gap);
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);

  .memorial-collage-grid-item {
    overflow: hidden;

    &:first-child {
      border-top-left-radius: var(--memorial-collage-radius);

      > img {
        border-top-left-radius: var(--memorial-collage-radius);
      }
    }

    &:last-child {
      border-bottom-right-radius: var(--memorial-collage-radius);

      > img {
        border-bottom-right-radius: var(--memorial-collage-radius);
      }
    }

    > img {
      height: 100%;
      width: 100%;
    }
  }

  &[data-collage-size="2"] {
    .memorial-collage-grid-item {
      &:nth-child(2) {
        grid-column: 3 / 5;
        grid-row: 1 / 3;
      }

      &:last-child {
        border-top-right-radius: var(--memorial-collage-radius);

        > img {
          border-top-right-radius: var(--memorial-collage-radius);
        }
      }
    }
  }

  &[data-collage-size="2"],
  &[data-collage-size="5"] {
    .memorial-collage-grid-item {
      &:first-child {
        border-bottom-left-radius: var(--memorial-collage-radius);
        grid-column: 1 / 3;
        grid-row: 1 / 3;

        > img {
          border-bottom-left-radius: var(--memorial-collage-radius);
        }
      }
    }
  }

  &[data-collage-size="5"] {
    .memorial-collage-grid-item {
      &:nth-child(3) {
        border-top-right-radius: var(--memorial-collage-radius);

        img {
          border-top-right-radius: var(--memorial-collage-radius);
        }
      }
    }
  }

  &[data-collage-size="8"] {
    .memorial-collage-grid-item {
      &:nth-child(4) {
        border-top-right-radius: var(--memorial-collage-radius);

        img {
          border-top-right-radius: var(--memorial-collage-radius);
        }
      }

      &:nth-child(5) {
        border-bottom-left-radius: var(--memorial-collage-radius);

        img {
          border-bottom-left-radius: var(--memorial-collage-radius);
        }
      }
    }
  }
}
