// Memorial creation page

// TODO: This is private only with the exception of _editable_content_modal.html
.edit-memorial-container {
  .memorial-deceased-form-container {
    justify-content: space-between;
  }

  .date-input-checkbox-container {
    margin-top: 20px;
  }

  .select-date-widget {
    display: flex;

    > div {
      background-size: 18px 11px;
      flex-grow: 1;

      @include media-breakpoint-between(xs, md) {
        background-position: right 8px center;
        background-size: 14px 8px;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }

      select {
        font-weight: $font-weight-normal;

        @include media-breakpoint-between(xs, md) {
          padding: 8px 10px;
        }
      }
    }
  }

  .body-card {
    > .cause-fine-print-and-help {
      padding-top: 40px;

      .el-input-group {
        &:last-of-type {
          margin-bottom: 0;
        }

        .clear-fundraising-user-link {
          img {
            height: 14px;
            margin-right: 3px;
            margin-top: 0;
            width: 14px;
          }
        }

        img {
          flex-shrink: 0;
          margin-right: 10px;
          margin-top: 3px;
        }
      }
    }

    &.body-card-no-padding {
      .cause-configuration-container {
        margin-top: 40px;
        padding-bottom: 0;

        .alert {
          margin: 0 0 20px;
        }
      }

      > .disclaimer {
        padding-bottom: 40px;
      }

      > .body-card-section {
        @include el-border-top;

        margin-top: 40px;
        padding-top: 40px;
      }
    }
  }

  &.memorial-onboarding-choice-container {
    .funeral-costs-question-container {
      > div {
        &:first-of-type {
          max-width: 100%;

          @include media-breakpoint-up(md) {
            max-width: 600px;
          }
        }
      }
    }

    .cause-tip {
      margin: 40px 0 0;

      @include media-breakpoint-up(lg) {
        margin: 0;
      }
    }
  }

  .create-memorial-preview {
    flex: 0 0 calc(50% - 40px);
    margin-top: 20px;
    min-height: 100%;
    position: relative;

    .inner {
      position: sticky;
      top: 20px;

      h3 {
        margin: 10px 0 0 100px;
        text-align: center;
      }

      img {
        max-width: 100%;
      }
    }
  }
}

.photo-tip {
  &.cause-tip {
    margin: 40px 0 0;

    @include media-breakpoint-up(md) {
      margin: 80px 0 0;
    }

    @include media-breakpoint-up(lg) {
      margin: 40px 0 0;
    }
  }
}

.cause-tip,
.photo-tip {
  border: 1px solid $medium-blue;
  border-radius: $border-radius-lg;
  margin-left: 20px;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 335px;
  }

  .tip-header {
    background-color: $light-blue;
    border-radius: $border-radius-lg $border-radius-lg 0 0;
    color: $dark-blue;
    padding: 15px 30px;

    img {
      margin-right: 10px;
    }
  }

  .tip-body {
    padding: 20px;

    &.tip-small {
      font-size: 1rem;
    }
  }
}

.deceased-metadata {
  margin-top: 20px;
  width: 100%;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }

  @include media-breakpoint-up(lg) {
    width: 50%;
  }

  .el-input-group {
    .borderless-input {
      margin-bottom: 40px;
    }

    .name-modifier-other {
      margin-top: 20px;
      width: 100%;
    }

    .nickname-container {
      .borderless-input {
        margin-bottom: 0;
      }
    }
  }
}

.create-disclaimer {
  margin-top: 0;
  padding: 0 20px;

  @include media-breakpoint-up(md) {
    margin-top: 20px;
    padding: 0;
  }
}

.partner-create-disclaimer {
  color: $dark-gray-text;
  font-size: 0.9375rem;
  margin: 20px;
  max-width: calc(100% - 40px);
  width: calc(100% - 40px);

  @include media-breakpoint-up(md) {
    margin: 20px 0 0;
    max-width: 40%;
    width: 40%;
  }
}
