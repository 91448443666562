// Public RSVPs

.rsvp-toggle {
  position: relative;
}

.rsvp-check {
  margin-right: 10px;
  margin-top: -3px;
}

.rsvp-response-container {
  border: 1px solid;
  border-radius: $border-radius-lg;
  margin-bottom: 40px;
  max-width: 532px;
  padding: 20px;

  .rsvp-dropdown-container {
    margin-bottom: 20px;
  }

  .rsvp-details {
    margin-top: 30px;
  }

  .btn-outline-secondary {
    --#{$prefix}btn-bg: #{$white};
  }

  @include media-breakpoint-between(xs, md) {
    max-width: none;
    width: 100%;
  }
}

.rsvp-attendee-formset-form {
  &.hidden {
    border: 0 !important;
    height: 0 !important;
    margin: 0 !important;
    max-height: 0 !important;
    overflow: hidden !important;
    padding: 0 !important;
  }
}
