.memory-selectors {
  align-items: flex-end;
  margin-bottom: 40px;
  margin-top: 20px;

  @include media-breakpoint-up(md) {
    align-items: normal;
    width: 100%;
  }

  a {
    @include media-breakpoint-between(xs, md) {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    &.tell-story {
      img {
        @include media-breakpoint-between(xs, md) {
          height: 25px;
        }
      }
    }

    img {
      @include media-breakpoint-between(xs, md) {
        height: 30px;
      }
    }
  }
}

.memory-selector {
  @include el-secondary-border;

  background-color: $white;
  border-radius: $border-radius-lg;
  color: var(--secondary-brand-color);
  display: block;
  flex-basis: 23%;
  flex-shrink: 0;
  font-size: 1.125rem;
  height: 124px;
  padding-top: 34px;
  text-align: center;

  p {
    margin-top: 10px;
  }

  @include media-breakpoint-between(xs, md) {
    flex-basis: 48%;
    height: 82px;
    margin: 20px 0 0;
    padding-bottom: 8px;
    padding-top: 16px;

    &:first-child {
      margin-top: 0;
    }

    p {
      font-size: 0.9375rem;
      margin: 0;
      padding-top: 4px;
    }
  }

  &.selected,
  &:hover {
    background-color: var(--secondary-brand-color);
    color: $white;

    img {
      &.selected {
        display: inline !important;
      }

      &.unselected {
        display: none !important;
      }
    }
  }

  img {
    &.selected {
      filter: brightness(0%) saturate(100%) invert(93%) sepia(94%) saturate(0%)
        hue-rotate(248deg) brightness(106%) contrast(106%);
    }

    &.unselected {
      filter: var(--secondary-brand-color-svg);
    }
  }
}

.share-photo {
  img {
    @include media-breakpoint-between(xs, md) {
      margin-top: -3px;
    }
  }
}

.give-condolences {
  img {
    @include media-breakpoint-between(xs, md) {
      margin-top: -5px;
    }
  }
}

.add-memory-details {
  .borderless-input {
    width: 100%;
  }

  .photo-input {
    @include media-breakpoint-between(xs, md) {
      input {
        width: 335px;
      }
    }

    img {
      margin-top: 40px;
    }
  }

  .text-input {
    margin-bottom: 10px;
  }

  .participants-input {
    font-size: 1.125rem;
    min-width: 100%;
    width: 100%;
  }
}

textarea {
  &.memory-text {
    height: 203px;
    width: 100%;
  }
}

.memories {
  &.only-one-memory {
    @include media-breakpoint-between(xs, md) {
      padding-bottom: 0;
    }
  }

  &.has-memories {
    border-bottom: 0;
    padding-bottom: 0;

    &.ends-with-memory {
      @include media-breakpoint-between(xs, lg) {
        @include el-border-bottom;

        padding-bottom: 40px;
      }
    }
  }

  .memory {
    border-bottom: 1px solid $slate-gray;
    padding: 20px;

    @include media-breakpoint-between(xs, md) {
      font-size: 1.125rem;
    }

    @include media-breakpoint-up(md) {
      padding: 20px 40px;
    }

    @include media-breakpoint-up(lg) {
      padding: 20px 0;
    }

    &.hidden-memory {
      border: 1px dashed $gray-500;
      border-radius: $border-radius-lg;
      margin: 20px 10px;
      padding: 10px;

      @include media-breakpoint-up(md) {
        margin: 20px 30px;
        padding: 20px;
      }

      @include media-breakpoint-up(lg) {
        margin: 20px -10px;
        padding: 20px 10px 10px;
      }
    }

    &.requires-moderation {
      border: 1px dashed $warning-orange;
      border-radius: $border-radius-lg;
      margin: 20px 10px;
      padding: 10px;

      @include media-breakpoint-up(md) {
        margin: 20px 30px;
        padding: 20px;
      }

      @include media-breakpoint-up(lg) {
        margin: 20px -10px;
        padding: 20px 10px 10px;
      }
    }

    &:first-child {
      padding-top: 40px;

      &.editable-user-post {
        padding-top: 10px;
      }
    }

    &:last-child {
      padding-bottom: 0;

      &:not(.hidden-memory) {
        border-bottom: 0;
      }
    }

    &.restricted {
      padding-bottom: 20px;
    }

    &.user-owned-memory {
      padding-top: 15px;

      @include media-breakpoint-up(md) {
        padding-top: 10px;
      }
    }

    .dark-gray-label {
      &.moderation-required-label {
        color: $warning-orange;
      }
    }

    .moderation-container {
      display: flex;
      justify-content: space-between;

      > .btn {
        flex: 0 0 calc(50% - 4px);
        min-width: 0;

        @include media-breakpoint-between(xs, xl) {
          font-size: 14px;
          line-height: 1;
          padding: 8px 0.25rem;
        }
      }
    }

    .memory-prompt-question-text {
      margin: 0 0 10px;
    }

    .memory-share-link {
      color: $lighter-gray-text;
      font-weight: $font-weight-normal;
    }

    .memory-photo-container {
      margin: 0 -20px;
      width: calc(100% + 40px);

      @include media-breakpoint-up(md) {
        margin: 0;
        width: 100%;
      }

      .full-memory-img {
        @include el-border;

        border-radius: 2px;
        margin-bottom: 5px;
        width: 100%;

        @include media-breakpoint-up(md) {
          margin-bottom: 8px;
          width: auto;
        }
      }
    }

    .photo-year-and-location {
      margin-bottom: 8px;
    }

    .heart-support-statement {
      display: inline-block;

      img {
        margin-left: 10px;
        margin-top: -5px;
      }
    }

    .memory-video-embed-container {
      border-radius: $border-radius-lg;
      height: 0;
      margin: 10px 0;
      overflow: hidden;
      padding-top: calc(9 / 16 * 100%);
      position: relative;
      width: 100%;

      iframe {
        border: 0;
        height: 100%;
        left: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    .memory-video-link-container {
      margin-bottom: 20px;
    }
  }

  .memory-reactions {
    img {
      margin: 7px 10px 0 0;
      min-width: 20px;
    }

    .memory-reaction-icon-wrapper {
      margin: 10px 0;

      img {
        margin: 0;
        vertical-align: baseline;
      }

      > div {
        align-content: center;
        margin-right: 10px;

        &:last-of-type {
          margin-right: 0;
        }

        &.counter-visible {
          margin-right: 8px;

          span {
            margin-left: 6px;
          }
        }

        span {
          color: $darker-gray-text;
          font-size: 0.9375rem;
          font-weight: $font-weight-semibold;
          margin-left: 10px;
        }
      }

      .heart-count {
        cursor: pointer;
      }
    }

    .memory-heart-users {
      @include el-light-border-bottom;

      > div {
        > div {
          width: 100%;

          @include media-breakpoint-up(md) {
            width: 260px;
          }

          @include media-breakpoint-up(lg) {
            width: calc(50% - 20px);
          }

          &:first-of-type {
            margin-right: 40px;
          }

          > div {
            margin-bottom: 10px;
          }
        }
      }

      .collapser {
        justify-content: flex-end;
        margin: 10px 0;

        img {
          margin: 0 0 3px 10px;
        }
      }
    }
  }

  .pagination-breadcrumbs,
  .toggle-photo-archive-controls {
    padding: 40px 20px 0;

    @include media-breakpoint-up(md) {
      padding: 40px 40px 0;
    }

    @include media-breakpoint-up(lg) {
      padding: 40px 0;
    }
  }
}

.btn-submit-memory {
  margin-left: 0 !important;
  margin-right: 0 !important;

  @include media-breakpoint-between(xs, md) {
    width: 100%;
  }
}

.btn-cancel-memory-edit {
  margin-left: 0 !important;
  margin-top: 20px !important;
  width: 100%;

  @include media-breakpoint-up(md) {
    margin-left: 20px !important;
    margin-right: 0 !important;
    width: auto;
  }
}

.multi-photo-upload-form {
  position: relative;

  .add-photo-memory-button {
    --#{$prefix}btn-bg: #{$white};

    border: 2px dashed;
    border-radius: $border-radius-lg;
    flex: 0 0 calc(50% - 10px);
    flex-direction: row;
    margin-bottom: 20px;
    margin-top: 40px;
    min-height: 0;
    padding: 8px;
    width: 100%;

    @include media-breakpoint-up(md) {
      flex-direction: column;
      margin-top: 0;
      min-height: 300px;
      padding: 0;
      width: auto;
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      min-height: 0;
      padding: 8px;
      width: 100%;
    }

    @include media-breakpoint-up(xl) {
      flex-direction: column;
      min-height: 300px;
      padding: 0;
      width: auto;
    }

    &:nth-child(2),
    &:nth-child(2n + 1) {
      flex: 0 0 100%;
      flex-direction: row;
      min-height: 0;
      padding: 8px;

      img {
        height: 40px;
        margin: 0 10px 0 0;
        padding: 8px;
        width: 40px;
      }
    }

    img {
      height: 22px;
      margin: 0 8px 0 0;
      width: 22px;

      @include media-breakpoint-up(md) {
        height: 60px;
        margin: 0 0 20px;
        width: 60px;
      }

      @include media-breakpoint-up(lg) {
        height: 22px;
        margin: 0 8px 0 0;
        width: 22px;
      }

      @include media-breakpoint-up(xl) {
        height: 60px;
        margin: 0 0 20px;
        width: 60px;
      }
    }
  }

  .multi-photo-upload-input {
    height: 0;
    opacity: 0;
    width: 0;
  }

  .overlay {
    background-color: $white;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .btn {
    &.save-memory-photo-formset-btn {
      margin: 10px 0 40px;
      width: 100%;
    }
  }
}

.photo-ingestion-modal {
  .modal-dialog {
    max-width: 800px;
  }

  .modal-body {
    padding: 20px 10px;
  }

  .ingestion-option {
    align-items: center;
    background-color: $white;
    border-radius: $border-radius-lg;
    box-shadow: $card-shadow;
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    margin: 10px 20px;
    padding: 40px;
    transition: all 0.2s ease-in-out;

    @include media-breakpoint-up(md) {
      margin: 10px;
    }

    &:hover {
      box-shadow: $card-shadow-hover;
      transform: translateY(-4px);
    }

    img {
      height: 60px;
      margin-bottom: 10px;
      width: 60px;
    }
  }
}

.photo-memory-forms-container {
  .photo-memory-form {
    background-color: $white;
    flex: auto;
    padding: 0;

    @include media-breakpoint-up(md) {
      border-radius: $border-radius-lg;
      box-shadow: $card-shadow;
      flex: 0 0 calc(50% - 10px);
      margin-bottom: 20px;
    }

    @include media-breakpoint-up(lg) {
      flex: auto;
    }

    @include media-breakpoint-up(xl) {
      flex: 0 0 calc(50% - 10px);
    }

    .participants {
      font-size: 1.125rem;
      max-width: 335px;
    }

    &:only-of-type {
      flex: 0 0 100%;
      padding: 0;

      .photo-memory-form-fields {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .caption {
          flex: 0 0 100%;
        }

        .location {
          flex: 0 0 100%;

          @include media-breakpoint-up(md) {
            flex: 0 0 calc(70% - 5px);
          }
        }

        .participants {
          min-width: 100%;
          width: 100%;
        }

        .year {
          flex: 0 0 100%;

          @include media-breakpoint-up(md) {
            flex: 0 0 calc(30% - 5px);
          }
        }
      }
    }

    &.removing {
      height: 0;
      margin: 0;
      max-height: 0;
      max-width: 0;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 0;
    }

    .photo-memory-form-photo {
      background-position: center;
      background-size: cover;
      border-radius: $border-radius-lg;
      position: relative;

      @include media-breakpoint-up(md) {
        border-radius: $border-radius-lg $border-radius-lg 0 0;
      }

      &::before {
        content: "";
        display: block;
        padding-top: 75%; // 4:3
        width: 100%;
      }

      .promote-to-header {
        bottom: 10px;
        display: flex;
        height: 30px;
        justify-content: center;
        left: 0;
        position: absolute;
        width: 100%;

        .el-checkbox-wrapper {
          background-color: $white-overlay;
          border-radius: 15px;
          padding: 0 14px;
        }
      }

      img {
        backdrop-filter: blur(20px);
        border-radius: $border-radius-lg;
        height: 100%;
        inset: 0;
        object-fit: contain;
        position: absolute;
        width: 100%;

        @include media-breakpoint-up(md) {
          border-radius: $border-radius-lg $border-radius-lg 0 0;
        }
      }

      .remove-photo-memory-button {
        background-color: $white;
        border-radius: 50%;
        box-shadow: $card-shadow-light;
        color: $dark-gray-text;
        height: 20px;
        line-height: 20px;
        margin: 8px;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 20px;
      }
    }

    .photo-memory-form-fields {
      padding: 20px 0;
      width: 100%;

      @include media-breakpoint-up(md) {
        padding: 20px;
        width: auto;
      }

      .copy-photo-memory-detail {
        color: $secondary-brand-color;
        font-size: 0.75rem;

        img {
          height: 12px;
          margin: -2px 4px 0;
          width: 12px;
        }
      }

      .dark-gray-label {
        .errorlist {
          font-size: 0.75rem;
          margin-bottom: 0;
          padding-top: 0;
        }
      }

      input {
        &[type="text"] {
          margin-bottom: 10px;
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
}

.social-photo-importer {
  .load-more {
    color: var(--secondary-brand-color);
    display: block;
    padding: 8px 10px;
    text-align: center;
    width: 100%;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 300px;
    padding: 0;
    position: relative;

    @include media-breakpoint-up(md) {
      height: auto;
    }

    .overlay {
      align-items: center;
      background-color: $white;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      left: 0;
      padding: 40px;
      position: absolute;
      top: 0;
      width: 100%;

      .error {
        .message {
          margin: 10px 0 20px;
        }
      }
    }
  }

  .album-list {
    flex-grow: 1;
    height: 100%;
    max-height: none;
    -webkit-overflow-scrolling: touch; // stylelint-disable-line property-no-vendor-prefix
    overflow-y: scroll;

    @include media-breakpoint-up(md) {
      height: auto;
      max-height: calc(95vh - 300px);
    }

    .album {
      align-items: center;
      border-bottom: 1px solid $border-color;
      display: flex;

      .thumbnail {
        height: 60px;
        margin-right: 10px;
        object-fit: cover;
        width: 60px;
      }

      .name {
        color: $dark-gray-text;
        font-weight: $font-weight-semibold;
      }
    }
  }

  .media-grid {
    align-items: flex-start;
    display: flex;
    flex-flow: row wrap;
    flex-grow: 1;
    height: 100%;
    max-height: none;
    -webkit-overflow-scrolling: touch; // stylelint-disable-line property-no-vendor-prefix
    overflow-y: scroll;
    padding: 0;
    place-content: flex-start left;

    @include media-breakpoint-up(md) {
      height: auto;
      max-height: calc(95vh - 300px);
    }

    .album-title {
      background-color: $white;
      box-shadow: 0 4px 5px -5px $black-with-33-percent-opacity;
      flex: 0 0 100%;
      justify-content: center;
      padding: 4px 8px;
      position: sticky;
      text-align: center;
      top: 0;
      z-index: $zindex-sticky;

      .album-back {
        display: block;
        left: 0;
        position: absolute;

        img {
          height: 18px;
          width: 18px;
        }
      }
    }

    &.maximum-selected {
      .media {
        cursor: not-allowed;
      }
    }

    .loading-more {
      flex: 0 100%;
      padding: 4px 10px;
      text-align: center;
    }

    .media {
      border: 1px solid $white;
      cursor: pointer;
      flex: 0 0 calc(25%);
      position: relative;

      &::before {
        content: "";
        display: block;
        height: 0;
        padding-bottom: 100%;
      }

      .album-icon {
        position: absolute;
        right: 4px;
        top: 4px;
      }

      .media-img {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
      }

      &:nth-child(2) {
        border-top-width: 0;
      }

      &:nth-child(3) {
        border-top-width: 0;
      }

      &:nth-child(4) {
        border-top-width: 0;
      }

      &:nth-child(5) {
        border-top-width: 0;
      }

      &:nth-child(4n + 2) {
        border-left-width: 0;
      }

      &:nth-child(4n + 1) {
        border-right-width: 0;
      }

      &.selected {
        border: 3px solid var(--secondary-brand-color);
      }
    }
  }

  .selected-media-container {
    background-color: $very-light-gray;
    border-top: 1px solid $border-color;
    padding-top: 10px;

    .selected-media-header {
      padding: 0 8px;
    }
  }

  .selected-media-grid {
    display: flex;
    flex-flow: row wrap;
    flex-grow: 0;
    justify-content: left;
    -webkit-overflow-scrolling: touch; // stylelint-disable-line property-no-vendor-prefix
    overflow-y: scroll;
    padding: 4px 0;

    .selected-media {
      border: 1px solid $white;
      cursor: pointer;
      flex: 0 0 10%;
      height: 62.5px;
      max-width: 10%;
      object-fit: cover;

      &:hover {
        border-color: $red;

        &::before {
          color: $white;
          content: "remove";
          margin-top: 45%;
          text-align: center;
          text-transform: uppercase;
        }
      }
    }
  }
}

.memory-meta-container {
  margin-bottom: 10px;
  margin-top: 20px;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }

  .user-profile-photo {
    flex: 0 0 auto;
    height: 50px;
    margin: 2px 12px 0 0;
    width: 50px;
  }
}

.sidebar {
  .column-section {
    &:first-child {
      &.column-section__memories {
        padding-top: 40px;
      }
    }
  }
}
